import React from "react";
import { enquireScreen } from "enquire-js";

import "./../CommonPage.less";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class PrivacyAgreement extends React.PureComponent {
  state = {
    isMobile,
    checkModal: true
    // isScroll: false,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }

  handleGoCheck = () => {
    this.props.history.push('/politicamexc');
  }

  render() {
    const {checkModal} = this.state
    
    return (
      <div className={"contentWrapper"}>
        <>
        <h2>Política de privacidad</h2>
        <p>
          El presente documento contiene la Política de Privacidad de PrestMan y
          se encuentra en armonía con la normativa vigente sobre Protección de
          Datos Personales (Ley N° 29733 Y Decreto Supremo 003-2013- JUS). En el
          presente documento, se señalan las finalidades de la información que
          usted nos otorga, quiénes son los encargados de tratarla, las medidas
          de protección y los medios para ejercer sus derechos como titular de
          los Datos Personales, entre otros aspectos. Por favor lea con
          detenimiento este documento para entender la información que
          recolectamos sobre usted, cómo la usamos y las circunstancias bajo las
          cuales la compartiremos con terceras partes.
        </p>
        <p></p>
        <p>Consentimiento</p>
        <p></p>
        <p>
          Al hacer uso de esta aplicación usted acepta esta Política de
          Privacidad y presta su consentimiento libre, expreso, inequívoco e
          informado para que esta aplicación recolecte y procese información del
          usuario. Asimismo, usted (el usuario) declara estar de acuerdo con
          todos los aspectos expuestos en este documento y nos autoriza a tratar
          sus datos personales para las finalidades expuestas.
        </p>
        <p></p>
        <p>
          Cabe precisar que, si bien la interacción con el usuario está prevista
          para ser realizada a través de la aplicación, PrestMan podría
          habilitar una página web para interactuar con los usuarios y el solo
          uso de esta implicaría la aceptación de esta Política de Privacidad en
          los términos señalados precedentemente.
        </p>
        <p></p>
        <p>
          Finalmente, PrestMan no requiere consentimiento para tratar sus datos
          personales obtenidos de fuentes accesibles al público; asimismo, podrá
          tratar sus datos personales de fuentes no públicas, siempre que dichas
          fuentes cuenten con su consentimiento para tratar y transferir dichos
          datos personales.
        </p>
        <p></p>
        <p>
          Para efectos de esta Política toda referencia a “nos”, “nosotros” o
          “nuestra” hace alusión” a PrestMan y cuando se refiere a “el usuario”
          o “los usuarios” o “usted” se entenderán a todas aquellas personas
          distintas a PrestMan que naveguen, ingresen, revisen, interactúen y
          proporcionen sus datos personales.
        </p>
        <p></p>
        <p>Información personal</p>
        <p></p>
        <p>
          Por información personal nos referimos a información que se encuentre
          asociada con una persona natural en específico y que puede ser
          razonablemente utilizada para identificar a esta persona.
        </p>
        <p></p>
        <p>
          La información personal no incluye a la información que haya pasado
          por un proceso de anonimización y que por ende no puede ser utilizada
          para identificar a una persona.
        </p>
        <p></p>
        <p>Información que podríamos recolectar sobre usted:</p>
        <p></p>
        <p>· Información proporcionada por usted:</p>
        <p></p>
        <p>
          - Información proporcionada por usted al llenar los datos en nuestra
          aplicación y/o al interactuar con nosotros por vía telefónica, correo
          electrónico, libro de reclamaciones o de cualquier otra forma. Esto
          incluye, pero no se limita a, la información proporcionada al
          registrar como usuario para utilizar la aplicación, realizar
          operaciones con la misma o reportar algún problema en la página web
          y/o aplicación.
        </p>
        <p></p>
        <p>- La información recolectada por lo menos incluirá:</p>
        <p></p>
        <p>· Nombre.</p>
        <p></p>
        <p>· Apellidos.</p>
        <p></p>
        <p>· Correo electrónico.</p>
        <p></p>
        <p>· Número del documento oficial de identidad.</p>
        <p></p>
        <p>· Número de teléfono(solo para verificación de inicio de sesión).</p>
        <p></p>
        <p>· Sexo.</p>
        <p></p>
        <p>· Fecha de nacimiento.</p>
        <p></p>
        <p>· Dirección.</p>
        <p></p>
        <p>· Números de cuenta bancarios e interbancarios.</p>
        <p></p>
        <p>· Ubicación aproximada.</p>
        <p></p>
        <p>· Fotografía.</p>
        <p></p>
        <p>· Copia del documento oficial de identidad.</p>
        <p></p>
        <p>· Situación laboral.</p>
        <p></p>
        <p>· Cualquier otro tipo de información que usted nos proporcione.</p>
        <p></p>
        <p>
          - Asimismo, la aplicación le solicita agregar datos de terceras
          personas a efectos de poder evaluar adecuadamente el otorgamiento de
          un crédito, dicha información puede ser:
        </p>
        <p></p>
        <p>
          · Datos sobre el correo electrónico, ocupación, vínculo con el
          usuario, entre otros datos.
        </p>
        <p></p>
        <p>
          - En tales supuestos donde se brinda información correspondiente a
          otras personas, debes saber que PrestMan actúa como un encargado de
          tratamiento de dicha información (es decir, como un intermediario),
          siendo la responsabilidad de quien brinda estos datos personales,
          obtener previamente el consentimiento libre, expreso, inequívoco e
          informado de dichas personas. (ver punto 11)
        </p>
        <p></p>
        <p>· Información recolectada sobre usted:</p>
        <p></p>
        <p>
          - Detalles de las transferencias de dinero realizadas como parte del
          otorgamiento del crédito, incluyendo la ubicación geográfica desde la
          cual se genera.
        </p>
        <p></p>
        <p>
          - Ubicación técnica, incluyendo la dirección IP utilizada para
          conectarse desde su computadora a internet, la información de ingreso
          a su cuenta de usuario, tipo de navegador y versión, configuración
          horaria, plug-ins asociados, sistema operativo y plataforma, etc.
        </p>
        <p></p>
        <p>
          - Información sobre su visita, incluyendo el clickstream completo de
          URL hacia, a lo largo de, y desde nuestra página web (incluyendo fecha
          y hora); lo que se vio y las búsquedas que se realizaron, tiempos de
          respuesta de la página, errores de descarga, tiempos de visita a
          ciertas páginas, información de interacción con la página y métodos
          usados para salir de la página, así como cualquier número de teléfono
          utilizado para contactar a servicio al cliente.
        </p>
        <p></p>
        <p>· Información que recibimos de otras fuentes:</p>
        <p></p>
        <p>
          - Nosotros podríamos recibir información acerca de usted si utiliza
          cualquier otra página web operada por nosotros o asociada a cualquier
          otro servicio provisto por nosotros.
        </p>
        <p></p>
        <p>
          - Nosotros también podríamos recibir información de los bancos u otras
          instituciones financieras utilizadas para trasferir fondos.
        </p>
        <p></p>
        <p>
          - También trabajamos cercanamente con terceras partes (como agencias
          de historial crediticios, proveedores de análisis de datos, redes de
          publicidad negocios aliados, entre otros y podríamos recibir
          información de ellos.
        </p>
        <p></p>
        <p>Uso de la información recolectada</p>
        <p></p>
        <p>
          La recolección y tratamiento de datos personales, así como los
          eventuales encargos, transferencias y flujos transfronterizos que se
          realicen sobre estos a empresas vinculadas o proveedores de PrestMan,
          tiene las siguientes finalidades:
        </p>
        <p></p>
        <p>· Información proporcionada por usted o recolectada sobre usted:</p>
        <p></p>
        <p>
          - Para cumplir con nuestras obligaciones adquiridas en el marco de
          cualquier contrato entre usted y nosotros (incluyendo, pero sin
          limitarse a los términos y condiciones) y para proveerle de
          información, productos y servicios que requiera.
        </p>
        <p></p>
        <p>
          - Validar la identidad e información del usuario en cumplimiento con
          lo establecido por la normativa vigente de la SBS, que regula la
          prevención y lavado de activos y del financiamiento del terrorismo
          para las empresas de cambio de divisas.
        </p>
        <p></p>
        <p>
          - Validar la identidad del usuario y la veracidad de la información
          entregada con respecto a fuentes de información externos, como el
          RENIEC y otras fuentes accesibles al público o proveedores de
          información para fines vinculados con la prevención del lavado de
          activos y financiamiento del terrorismo.
        </p>
        <p></p>
        <p>
          - Para proveerle información sobre otros bienes y servicios que
          ofrecemos, que sean similares a aquellos que usted ya utiliza.
        </p>
        <p></p>
        <p>
          - Para el envío de anuncios y oferta de servicios por medio del correo
          electrónico registrado en la página web, envío de SMS y/o llamadas al
          número telefónico que el Cliente ha registrado.
        </p>
        <p></p>
        <p>
          - Para la realización efectiva de cobranza, sea de forma directa o a
          terceros a quienes le hayamos conferido tal labor o cedido nuestra
          posición contractual.
        </p>
        <p></p>
        <p>
          - Para la ejecución y mantenimiento de otras relaciones contractuales
          mantenidas entre Usted y PrestMan, tales como gestiones de cobranza y
          del desarrollo de sus operaciones.
        </p>
        <p></p>
        <p>
          - Para asegurarnos que el contenido presentado en nuestra aplicación
          y/o página web sea el más idóneo de acuerdo con sus preferencias y
          dispositivos.
        </p>
        <p></p>
        <p>
          - Para cumplir con todos los requerimientos legales y regulatorios
          impuestos por las autoridades competentes.
        </p>
        <p></p>
        <p>- Para administrar nuestra aplicación y/o página web.</p>
        <p></p>
        <p>
          - Para proponerle el uso de nuestros servicios y su adecuada
          utilización.
        </p>
        <p></p>
        <p>
          - Para operaciones internas como solución de problemas operativos,
          análisis de datos, pruebas, así como fines estadísticos y de
          investigación.
        </p>
        <p></p>
        <p>
          - Para permitirle participar en partes interactivas de nuestros
          servicios cuando usted desee hacerlo.
        </p>
        <p></p>
        <p>
          - Para asegurar, mantener y mejorar la seguridad de nuestra aplicación
          y/o página web.
        </p>
        <p></p>
        <p>
          - Para medir y entender la efectividad de la publicidad enviada a
          usted y a otros, con el fin de poder enviarle publicidad relevante y
          adecuada a sus intereses.
        </p>
        <p></p>
        <p>
          - Para brindarle recomendaciones acerca de otros bienes y servicios
          que podrían interesarle.
        </p>
        <p></p>
        <p>
          - Para la gestión adecuada de la cartera de cobranzas y/o su eventual
          cesión de posición contractual.
        </p>
        <p></p>
        <p>
          - Transferencia de datos específicos a las entidades bancarias, cuando
          el proceso operativo exija la transferencia de dinero a cuentas
          bancarias del usuario en entidades externas, como de terceras personas
          que a solicitud y por instrucciones del usuario se efectúen.
        </p>
        <p></p>
        <p>
          - Utilizar información debidamente anonimizada o disociada con el fin
          de desarrollar nuestros servicios o investigar tendencias en el sector
          fintech o realizar cualquier tipo de actividades de valor histórico o
          estadístico.
        </p>
        <p></p>
        <p>- Para fines de la prestación del servicio.</p>
        <p></p>
        <p>- Para notificar cambios en nuestro servicio.</p>
        <p></p>
        <p>
          - Resolver y dar respuesta a las quejas y problemas respecto al
          servicio, así como absolver consultas, peticiones, sugerencias y otro
          tipo de información que se requiera a través de nuestros canales de
          comunicación.
        </p>
        <p></p>
        <p>
          - Facilitar cualquier información que PrestMan crea que puede ser de
          tu interés.
        </p>
        <p></p>
        <p>
          - Investigar y detectar posibles fraudes u otras violaciones a
          nuestros Términos y Condiciones o contra la presente Política de
          Privacidad y/o intentos de dañar a nuestros usuarios o visitantes.
          También usamos tus datos personales si creemos que es necesario por
          motivos de seguridad.
        </p>
        <p></p>
        <p>
          - Cumplir con la normativa vigente establecida por la Unidad de
          Inteligencia Financiera (UIF) de la SBS, tales como el registro de
          operaciones, el registro de operaciones, informar de operaciones
          sospechosas a la UIF o dar cumplimiento a cualquier regulación vigente
          y futura establecida por la SBS u otras disposiciones legales.
        </p>
        <p></p>
        <p>
          - Solicitar ampliación de información en caso se identifique que eres
          una Persona Expuesta Políticamente (PEP) o familiar de PEP.
        </p>
        <p></p>
        <p>
          - Solicitar ampliación de información, en caso tu nombre u operación
          sea identificado como operación de alerta o inusual.
        </p>
        <p></p>
        <p>- Realizar sondeos y encuestas sobre nuestros servicios.</p>
        <p></p>
        <p>
          - Almacenar tu información en servicios de almacenamiento en la nube
          en el extranjero. Ello resulta indispensable para poder prestarte el
          servicio a través de nuestra página web y aplicación.
        </p>
        <p></p>
        <p>· Información que recibimos de otras fuentes:</p>
        <p></p>
        <p>
          - Nosotros podríamos combinar la información brindada por usted con
          información recolectada de otras fuentes con la finalidad de
          actualizar datos que se encuentren incompletos o sustituirlos, en base
          a consultar de fuentes de acceso público.
        </p>
        <p></p>
        <p>
          - Esta información, y la información combinada, podría ser usada para
          cualquiera de los usos y fines señalados en el presente documento.
        </p>
        <p></p>
        <p>
          - La siguiente información se recoge de forma segura y mantenemos tus
          datos seguros.
        </p>
        <p></p>
        <p>· Información recaudada al utilizar la aplicación y/o web</p>
        <p></p>
        <p>- SMS(registro de SMS)</p>
        <p></p>
        <p>
          · Recopilaremos todos sus datos SMS, pero solo monitoreamos sus
          mensajes relacionados con transacciones financieras.
        </p>
        <p></p>
        <p>
          · Para ser específicos, monitorearemos el nombre del remitente, la
          descripción y el monto de la transacción para realizar la evaluación
          del riesgo crediticio.
        </p>
        <p></p>
        <p>
          · Lo anterior nos permite identificarte adecuadamente y verificar si
          calificas como persona a ser financiada por nosotros.
        </p>
        <p></p>
        <p>
          Subiremos los datos de SMS al servidor de PrestMan
          (https://go.prestman.tech/api) a través de una conexión
          segura.
        </p>
        <p></p>
        <p>- Imagen</p>
        <p></p>
        <p>
          · Necesitamos su permiso para acceder a la información de
          almacenamiento, lo cual permitirá que la aplicación le solicite cargar
          fotos y/o documentos durante el proceso de solicitud de préstamo para
          completar la solicitud. PrestMan cargará y transmitirá los datos de
          almacenamiento desde su teléfono a
          https://go.prestman.tech/api
        </p>
        <p></p>
        <p>- Datos de Contactos</p>
        <p></p>
        <p>
          Necesitamos que elija manualmente los contactos de emergencia de su
          lista de contactos, incluyendo el nombre y el número de teléfono. Esta
          función es útil cuando no podemos comunicarnos contigo. Solo los
          contactos seleccionados manualmente por los usuarios se enviarán de
          manera encriptada a nuestra aplicación
          (https://go.prestman.tech/api) y se almacenarán durante 1
          año a partir de la fecha de carga. Nunca recopilaremos su información
          sin su consentimiento. Si desea eliminar sus datos privados, puede
          enviar un correo electrónico a servicio@prestman.tech. Envíe una solicitud
          al servicio de atención al cliente y los eliminaremos manualmente
          dentro de 3-5 días hábiles.
        </p>
        <p></p>
        <p>- Ubicaciones</p>
        <p></p>
        <p>
          · Recopilamos y monitoreamos la información acerca de las ubicaciones
          de sus dispositivos para la evaluación del perfil del cliente y para
          evitar fraudes.
        </p>
        <p></p>
        <p>
          · Subiremos los datos de ubicaciones al servidor de PrestMan
          (https://go.prestman.tech/api).
        </p>
        <p></p>
        <p>- Celular:</p>
        <p></p>
        <p>
          · Recopilamos y monitoreamos información acerca de su dispositivo,
          incluyendo nombre, modelo, región, lengua, código de identificación,
          información de hardware y software, estatus, hábitos de uso,
          identificadores únicos como IMEI y números seriales.
        </p>
        <p></p>
        <p>
          · Dicha información nos es útil para identificar el dispositivo desde
          donde proviene la solicitud de préstamo y otras adicionales, para
          evaluar adecuadamente tu perfil y evitar fraudes.
        </p>
        <p></p>
        <p>
          · Además, recogeremos tu lista detallada de aplicaciones instaladas
          para evaluar tu propensión al préstamo y tu situación de deuda.
        </p>
        <p></p>
        <p>
          · Subiremos los datos de celular al servidor de PrestMan
          (https://go.prestman.tech/api).
        </p>
        <p></p>
        <p>- Cámara:</p>
        <p></p>
        <p>
          · Se pedirá acceso a la cámara del dispositivo móvil para que puedas
          identificarte por medio de un “selfie” y además puedas proporcionarnos
          los documentos necesarios para el proceso de solicitud y evaluación de
          tu perfil de cliente.
        </p>
        <p></p>
        <p>
          · Subiremos los datos de cámara al servidor de PrestMan
          (https://go.prestman.tech/api).
        </p>
        <p></p>
        <p>- Calendario</p>
        <p></p>
        <p>
          · Solicitaremos acceso a su calendario, para poder agendar la fecha de
          pago respectiva y los recordatorios respectivos.
        </p>
        <p></p>
        <p>
          · De esta manera, gestionamos nuestro riesgo de crédito y evitamos
          pagos vencidos.
        </p>
        <p></p>
        <p>
          · Subiremos los datos de calendario al servidor de PrestMan
          (https://go.prestman.tech/api).
        </p>
        <p></p>
        <p>- Actividad en apps :</p>
        <p></p>
        <p>
          ·Necesitamos obtener la actividad en apps, incluido nombre del
          paquete, nombre de la aplicación, la hora de la primera instalación y
          la hora de actualización de la aplicación, para evaluar si el cliente
          es elegible para solicitar un préstamo.
        </p>
        <p></p>
        <p>
          ·Cargaremos esta información a nuestro servidor
          (https://go.prestman.tech/api) encriptado, y se usa para
          identificar y analizar comportamiento y riesgo en múltiples préstamos
          para evaluar si un préstamo puede procesarse, lo que ayuda a prevenir
          el fraude.
        </p>
        <p></p>
        <p>- Información personal del usuario</p>
        <p></p>
        <p>
          ·Para realizar control de riesgo y evaluar la elegibilidad del
          usuario, necesitamos usar las informaciones personales incluido nombre
          completo, estado civil, género, fecha de nacimiento, edad, país,
          identificación oficial vigente, documento nacional de identidad (DNI),
          correo electrónico, datos académicos, dirección residencial.
        </p>
        <p></p>
        <p>
          ·Realizamos depósito bancario directamente mediente el número de
          cuenta proporcionados por los usuarios.
        </p>
        <p></p>
        <p>- El cooperador</p>
        <p></p>
        <p>
          El SDK que utilizamos para conectar con el servicio de proveedor de
          Deeplink se entrega a través de múltiples canales (por ejemplo,
          Google, Facebook) con el fin de distinguir la fuente de atribución del
          canal del usuario y transmitir la identidad de cada canal, pero
          nuestro socio Deeplink no accede ni utiliza ninguna información de la
          cuenta social del usuario, tampoco nuestra propia app accede ni
          utiliza ninguna información de la cuenta social del usuario.
        </p>
        <p></p>
        <p>
          · En resumen, las finalidades para las que utilizaremos tus datos
          serán las siguientes:
        </p>
        <p></p>
        <p>
          - Nosotros podríamos combinar la información brindada por usted con
          información recolectada de otras fuentes con la finalidad de
          actualizar datos que se encuentren incompletos o sustituirlos, en base
          a consultar de fuentes de acceso público.
        </p>
        <p></p>
        <p>
          - Esta información, y la información combinada, podría ser usada para
          cualquiera de los usos y fines señalados en el presente documento.
        </p>
        <p></p>
        <p>
          - Para brindarte nuestros servicios y mejorar tu experiencia en
          nuestra página web y aplicación.
        </p>
        <p></p>
        <p>
          - Para fines de funcionalidad y operatividad de la web y la
          aplicación.
        </p>
        <p></p>
        <p>
          - Para poder brindarte soporte, resolver cualquier duda o consulta y
          resolver tus reclamos.
        </p>
        <p></p>
        <p>- Para proteger tu seguridad y la de nuestra web y aplicación.</p>
        <p></p>
        <p>
          - Para satisfacer un interés legítimo (que no anula nuestro deber de
          proteger tus datos personales) como la investigación y el desarrollo
          de nuestros servicios, promover el sitio web y proteger nuestros
          derechos e intereses legales.
        </p>
        <p></p>
        <p>
          - Para procesar tus datos con el objeto de cumplir con una obligación
          legal.
        </p>
        <p></p>
        <p>
          - Para procesar tus datos con el objeto de cumplir con una obligación
          contractual.
        </p>
        <p></p>
        <p>Calidad de los datos personales</p>
        <p></p>
        <p>
          Los datos personales solicitados tienen el carácter de adecuados,
          pertinentes y no excesivos en relación con la finalidad para la cual
          se recopilan.
        </p>
        <p></p>
        <p>
          Declaras que toda la información proporcionada es verdadera, completa,
          exacta y actualizada. Asimismo, asumes la responsabilidad sobre la
          veracidad, exactitud, integridad y vigencia de dicha información
          PrestMan no asume la responsabilidad por cualquier daño o perjuicio
          que pudiera ocasionarse como resultado del incumplimiento de tal
          deber.
        </p>
        <p></p>
        <p>Revelación de información personal:</p>
        <p></p>
        <p>
          - Nosotros podríamos compartir su información personal con cualquier
          otro miembro de nuestro grupo económico, es decir, nuestras empresas
          hermanas, subsidiarias y/o holding.
        </p>
        <p></p>
        <p>
          - Del mismo modo, podríamos compartir su información personal con las
          empresas a las que le encarguemos nuestras carteras de cobranza y/o le
          cedamos nuestra posición contractual.
        </p>
        <p></p>
        <p>
          - Finalmente, nosotros podemos compartir su información personal a las
          entidades públicas o privadas a quienes por ley podamos o debamos
          proveerle dicha información.
        </p>
        <p></p>
        <p>
          Podríamos compartir su información personal con terceros
          seleccionados, incluyendo:
        </p>
        <p></p>
        <p>
          - Negocios aliados, proveedores o terceros contratados para proveer
          cualquier contrato que tengamos con usted o para ayudar a mejorar
          nuestro servicio.
        </p>
        <p></p>
        <p>
          - Agencias de publicidad que requieran datos para seleccionar y enviar
          avisos relevantes a usted o terceros.
        </p>
        <p></p>
        <p>
          - Proveedores de análisis de datos y de buscadores que nos asistan en
          la mejora y optimización de nuestros servicios.
        </p>
        <p></p>
        <p>
          - Entes reguladores o autoridades en el ejercicio de sus funciones que
          así nos lo demanden.
        </p>
        <p></p>
        <p>
          - Transferiremos datos personales necesarios a entidades financieras
          cuando el proceso operativo que se realiza en la plataforma exija la
          transferencia de dinero a cuentas bancarias del usuario en entidades
          externas.
        </p>
        <p></p>
        <p>Revelación de información personal con terceros:</p>
        <p></p>
        <p>
          - En caso se venda o compre cualquier unidad del negocio o activo y
          sea necesario revelar su información personal a potenciales
          compradores o vendedores de dichas unidades de negocio o activos.
        </p>
        <p></p>
        <p>
          - Si PrestMan o todos sus activos son adquiridos por un tercero, en
          cuyo caso la información personal recolectada podría ser uno de los
          activos a transferir.
        </p>
        <p></p>
        <p>
          - Si es que estamos bajo obligación de revelar o compartir su
          información personal para cumplir con cualquier obligación legal, o
          para poder aplicar los términos y condiciones o cualquier documento al
          cual se haga referencia en éstos, o para proteger los derechos,
          propiedad y seguridad de PrestMan, de nuestros usuarios o de terceros.
        </p>
        <p></p>
        <p>
          - Esto incluye intercambiar información con otras compañías y
          organizaciones con objetivos de protección frente a fraudes,
          investigación relacionada con crímenes financiero o proveedor datos
          para asistir al cumplimiento de la ley.
        </p>
        <p></p>
        <p>Dónde almacenamos su información personal:</p>
        <p></p>
        <p>
          - La información recolectada podría ser transferida y almacenada en un
          destino fuera del Perú. Esta podría ser también procesada por
          trabajadores operando fuera del Perú que trabajen para nosotros o para
          algún proveedor.
        </p>
        <p></p>
        <p>
          - Estos trabajadores podrían estar asociados en actividades como la
          provisión de servicios de soporte o de análisis de datos.
        </p>
        <p></p>
        <p>
          - Al enviar su información personal usted reconoce que se encuentra de
          acuerdo con esta transferencia, almacenamiento o procesamiento.
          Nosotros tomaremos todas las medidas necesarias para asegurarnos de
          que su información sea tratada de forma segura y de acuerdo con las
          políticas de privacidad que se establecen en este documento.
        </p>
        <p></p>
        <p>
          - Toda la información que nos envíe se almacena en nuestros servidores
          seguros. Cualquier transacción de pago se encriptará usando SLL y/o
          alguna otra tecnología de seguridad cibernética.
        </p>
        <p></p>
        <p>
          - Usted es responsable de mantener la confidencialidad de la
          contraseña seleccionada para acceder a ciertas partes de la aplicación
          y/o del código secreto que le enviemos a través de un mensaje de
          texto.
        </p>
        <p></p>
        <p>
          - Le pedimos no compartir su contraseña y/o código secreto con nadie
          ya que PrestMan no se hará responsable ante la pérdida, mal uso o robo
          de su contraseña o clave secreta.
        </p>
        <p></p>
        <p>
          - Desafortunadamente la transmisión de información a través de
          internet no es completamente segura. A pesar de que haremos el mayor
          esfuerzo posible para proteger su información, no podemos garantizar
          la seguridad de la información transmitida a nuestra aplicación y/o
          página web; cualquier transmisión es bajo su propio riesgo. Una vez
          recibida la información, utilizamos procedimientos estrictos y
          procesos de seguridad para evitar cualquier acceso a esta sin
          autorización.
        </p>
        <p></p>
        <p>
          - Nosotros limitamos el acceso a su información solo personal a los
          empleados de PrestMan que tengan necesidad de conocerla por razones
          asociadas a los servicios brindados.
        </p>
        <p></p>
        <p>
          - Continuamente capacitamos a nuestros trabajadores sobre la
          importancia de la confidencialidad y privacidad de la información de
          los usuarios.
        </p>
        <p></p>
        <p>
          - Mantenemos proceso que cumplen con los requerimientos legales para
          proteger su información personal de cualquier acceso no autorizado.
        </p>
        <p></p>
        <p>Uso de cookies u otras tecnologías similares</p>
        <p></p>
        <p>
          - Al ingresar a nuestro sitio web y/o aplicación, se utilizan
          elementos tecnológicos conocidos como cookies.
        </p>
        <p></p>
        <p>
          - Los cookies son pequeños ficheros de información que se almacenan en
          tu ordenador o dispositivo móvil. Esto es una práctica común en todos
          los sitios web y aplicaciones.
        </p>
        <p></p>
        <p>
          - Nuestra aplicación y/o página web utiliza cookies para rastrear la
          actividad del usuario para así poder mejorar la interfaz y experiencia
          del usuario.
        </p>
        <p></p>
        <p>- Los cookies no se usan para identificarte personalmente.</p>
        <p></p>
        <p>
          - La utilización de nuestro sitio web y/o aplicación implica su
          aceptación del uso de cookies de nuestra parte.
        </p>
        <p></p>
        <p>
          - Recuerde que es posible desactivar las cookies almacenadas en su
          computadora cambiando la configuración de su navegador. Sin embargo,
          esto podría afectar el correcto funcionamiento de nuestra página web
          y/o aplicación
        </p>
        <p></p>
        <p>
          Ejercicio del Derecho de Acceso, Rectificación, Cancelación y
          Oposición de la información por parte de los usuarios (ARCO).
        </p>
        <p></p>
        <p>Derechos ARCO:</p>
        <p></p>
        <p>
          - Usted puede ejercer sus derechos de Acceso, Rectificación,
          Cancelación y Oposición (“Derechos ARCO”), así como los demás derechos
          concedidos por la legislación de protección de datos personales.
        </p>
        <p></p>
        <p>
          - Los Derechos ARCO sirven para que puedas: (i) Acceder a tu
          información en nuestras bases de datos; (ii) Rectificar estos datos,
          (iii) Eliminar tus datos de nuestra base de datos, y (iv) Oponerte a
          que tus datos se usen si descubres que están siendo usados para un fin
          no descrito en este documento.
        </p>
        <p></p>
        <p>
          - Eliminación de cuentas y datos:Le brindamos el derecho de cancelar
          su cuenta después de liquidar las órdenes de préstamo. Si desea dejar
          de usar nuestro servicio y eliminar su cuenta, ingrese a
          https://www.prestman.tech/suyodatos para enviar una solicitud de
          eliminación, le pediremos que proporcionar información de su cuenta y
          pruebas relacionadas para verificar y validar su identidad; Después de
          la verificación, eliminaremos su cuenta y los datos relacionados.
        </p>
        <p></p>
        <p>Sobre el ejercicio de los derechos ARCO</p>
        <p></p>
        <p>
          - Para poder ejercer estos derechos, los usuarios deberán dirigir la
          solicitud a la siguiente dirección de correo electrónico
          servicio@prestman.tech con el asunto “Protección de Datos Personales”
          remitiendo la siguiente información:
        </p>
        <p></p>
        <p>· Copia (o escaneo) de DNI, carnet de extranjería o pasaporte.</p>
        <p></p>
        <p>
          · Si actúas por medio de un apoderado o representante legal, acompañar
          el poder o título que confirme que éste se encuentra autorizado para
          actuar como tal y ejercer cualquiera de estos derechos.
        </p>
        <p></p>
        <p>
          · Pedido sobre las medidas que PrestMan debería tomar respecto a tus
          datos personales.
        </p>
        <p></p>
        <p>· Documentos que sustenten tu pedido de ser el caso.</p>
        <p></p>
        <p>
          - PrestMan responderá la solicitud a través del correo electrónico que
          hayas proporcionado. El plazo de respuestas dependerá del tipo de
          solicitud que hayas formulado, conforme al Decreto Supremo No.
          003-2013-JUS y el Reglamento de la Ley No. 29733
        </p>
        <p></p>
        <p>
          - En caso no sea posible atender dicha solicitud por falta de
          información o de precisión, se te comunicará tal situación en los
          plazos detallados por la norma para ello.
        </p>
        <p></p>
        <p>
          Políticas de privacidad y ejercicio de derechos ARCO de otras páginas
          web
        </p>
        <p></p>
        <p>
          - Nuestra página web y/o aplicación podría contener links a otras
          páginas web o aplicaciones de empresas aliadas, afiliadas o
          publicitarias. En ese sentido debe tener en cuenta que si usted sigue
          un link hacia cualquiera de estas páginas y/o aplicaciones, las mismas
          disponen de sus propias políticas de privacidad y manejo de derechos
          ARCO, de las cuales usted deberá estar al tanto. PrestMan no asumirá
          ni aceptará ninguna responsabilidad dichas políticas.
        </p>
        <p></p>
        <p>Datos personales de terceros.</p>
        <p></p>
        <p>
          - En caso utilices y difundas datos personales de otros usuarios o de
          terceros garantizas a PrestMan que cuentas con el consentimiento
          libre, previo, expreso e inequívoco para la utilización de dichos
          datos personales.
        </p>
        <p></p>
        <p>
          - PrestMan no tiene el control y no es responsable por la difusión de
          dicho contenido. Sin embargo, si consideras que la introducción de
          cierto contenido en nuestro sitio web invade tu privacidad o la de
          terceros, te ofrecemos la opción de denunciar este hecho al correo
          electrónico: servicio@prestman.tech
        </p>
        <p></p>
        <p>Confidencialidad de los datos personales</p>
        <p></p>
        <p>
          - Los datos personales facilitados serán tratados con total
          confidencialidad y solo podrán ser conocidos y manejados por personal
          de PrestMan que necesita conocer dicha información para realizar las
          labores comprendidas en las finalidades descritas anteriormente.
        </p>
        <p></p>
        <p>
          - Nuestra plataforma se compromete a guardar secreto profesional
          permanente e indefinidamente respecto de los mismos.
        </p>
        <p></p>
        <p>
          - PrestMan no se responsabilizará por el tratamiento que realicen las
          entidades públicas o privadas a quienes por ley o contrato deba
          compartir dicha información.
        </p>
        <p></p>
        <p>
          - Respecto de ello, PrestMan se compromete a realizar sus mejores
          esfuerzos para que se mantenga la confidencialidad.
        </p>
        <p></p>
        <p>Cambios en la política de privacidad</p>
        <p></p>
        <p>
          - PrestMan se reserva el derecho de efectuar en cualquier momento
          cambios o modificaciones al presente Política de Privacidad, ante la
          necesidad de proporcionarle la protección continua y adecuada a sus
          datos personales, así como para la atención de novedades legislativas
          o jurisprudenciales, políticas internas, y prácticas del mercado.
        </p>
        <p></p>
        <p>
          - Los cambios y modificaciones estarán disponibles a través de los
          siguientes medios: anuncios en nuestras oficinas y en el portal web de
          PrestMan, a través de nuestro personal que recabe sus Datos
          Personales.
        </p>
        <p></p>
        <p>
          - Es aconsejable revisar el Política de Privacidad tras la enlace
          (https://www.prestman.tech/politica) para ser enterado de cada
          modificación que habrá en el futuro. Si tienen cualquier duda sobre el
          Política de Privacidad, por favor no duden en ponerse en contacto con
          atención al cliente tras correo electrónico (servicio@prestman.tech).
        </p>
        <p></p>
        <p>Cesión de posición contractual y cesión de derechos</p>
        <p></p>
        <p>
          - El usuario autoriza expresamente la cesión de derechos y/o la cesión
          de posición contractual de esta política de privacidad y de los datos
          personales en favor de cualquier persona que (i) quede obligada por la
          presente política y/o (ii) que sea el nuevo responsable de los bancos
          de datos que contengan los datos personales.
        </p>
        <p></p>
        <p>
          - Luego de producida la cesión, PrestMan no tendrá ninguna
          responsabilidad con respecto de cualquier hecho que ocurra a partir de
          la fecha de la cesión.
        </p>
        <p></p>
        <p>
          - El nuevo responsable del banco de datos asumirá todas y cada una de
          las obligaciones de PrestMan establecidas en la presente política
          respecto al tratamiento, resguardo y conservación de los datos
          personales.
        </p>
        <p></p>
        <p>
          Sobre el carácter obligatorio de los datos personales, y las
          consecuencias de no proporcionar la información personal requerida
        </p>
        <p></p>
        <p>
          - La recopilación de los datos personales es necesaria y obligatoria
          para poder cumplir con las finalidades descritas en la presente
          política.
        </p>
        <p></p>
        <p>
          - En caso no nos permitas acceder a la información personal requerida,
          te comunicamos que, no podremos brindarte los servicios que prestamos
          a través de nuestra página web y aplicación móvil, y en consecuencia,
          no podrás realizar las operaciones financieras que en ellas se
          ofrecen.
        </p>
        <p></p>
        <p>Seguridad de los datos personales</p>
        <p></p>
        <p>
          - En cumplimiento de la normativa vigente, hemos adoptado las medidas
          técnicas y organizativas de seguridad necesarias para garantizar la
          protección de los datos personales conservados, transmitidos y
          procesados de nuestros usuarios con el fin de evitar su alteración,
          pérdida o el tratamiento o acceso no autorizados que puedan afectar a
          la integridad, confidencialidad y disponibilidad de la información,
          teniendo en cuenta el estado de la tecnología, la naturaleza de los
          datos almacenados y los riesgos a los que están expuestos.
        </p>
        <p></p>
        <p>
          - Asimismo, los datos personales son recolectados a través del canal
          cifrado y como parte del proceso de registro. De igual forma, la
          contraseña utilizada en el momento de registro se encuentra cifrada.
        </p>
        <p></p>
        <p>
          - Por su parte, la información entre el navegador y la página web -o
          aplicación móvil-, se transfiere de forma encriptada a través de
          Secure Socket Layer (SSL). Sobre este último punto, al transmitir
          información.
        </p>
        <p></p>
        <p>
          - No obstante, a pesar de que en PrestMan realizamos nuestros mejores
          esfuerzos para proteger tus datos personales mediante el empleo de
          diversas técnicas de seguridad, la transmisión de información mediante
          las redes de comunicación y de Internet no es totalmente segura debido
          a actos ilícitos como ciberataques o acciones imputables al propio
          usuario al no tener precaución con sus propios datos.
        </p>
        <p></p>
        <p>
          - En tal sentido, toda la información que nos proporciones se enviará
          por tu cuenta y riesgo. PrestMan no se hace responsable por
          interceptaciones ilegales o violación de sus sistemas o bases de datos
          por parte de personas no autorizadas, así como la indebida utilización
          de la información obtenida por esos medios, o de cualquier intromisión
          ilegítima que escape al control de ésta y que no le sea imputable.
        </p>
        <p></p>
        <p>
          - A pesar de lo anterior, en caso de que PrestMan detecte una
          violación a la seguridad de los datos personales proporcionados, y
          ello signifique un alto riesgo para tus derechos y libertades, te
          comunicaremos inmediatamente tal situación en un lenguaje claro y
          sencillo, de modo que puedas comprender fácilmente la naturaleza de la
          infracción cometida contra tus datos personales.
        </p>
        <p></p>
        <p>
          - Dicha comunicación no será necesaria cuando PrestMan haya adoptado
          medidas de protección técnicas y organizativas apropiadas aplicadas a
          los datos personales afectados, de modo que éstos resulten
          indescifrable para quien intentó acceder a ellos (ej. el cifrado).
          Tampoco será necesario cuando suponga un esfuerzo desproporcionado
          para PrestMan, ya que tratándose de varios usuarios afectados resulta
          más fácil para nosotros optar por una comunicación pública u otras
          opciones.
        </p>
        <p></p>
        <p>Ley y jurisdicción</p>
        <p></p>
        <p>
          - Esta política de privacidad y cualquier disputa o reclamo que surjan
          como consecuencia de la misma se encuentra normada en la ley peruana.
        </p>
        <p></p>
        <p>Resolución de conflictos</p>
        <p></p>
        <p>
          - Las partes se someten a la competencia de los jueces y tribunales de
          Lima, Cercado para resolver cualquier duda, diferencia o controversia
          que pudiera surgir entre las partes respecto a los alcances de las
          políticas de privacidad de PrestMan, renunciando al fuero que pudiera
          corresponderles en razón a su domicilio.
        </p>
        <p></p>
        <p>Dudas y requerimientos</p>
        <p></p>
        <p>
          - Preguntas, solicitudes y comentarios asociados a esta política de
          privacidad son bienvenidos y deberán ser dirigidos a
          servicio@prestman.tech
        </p>
        <p></p>
        <p>Contacto</p>
        <p></p>
        <p>
          Si tiene alguna pregunta o sugerencia sobre esta política de
          privacidad, no dude en ponerse en contacto con nosotros.
        </p>
        <p></p>
        <p>Correo electrónico: servicio@prestman.tech</p>
        <p></p>
        <p>Dirección: Lima, Perú</p>
        <p></p>
        <p>Horario de trabajo: 9:00 a 19:00 horas de lunes a viernes</p>
        </>

        {checkModal && 
          <div className={['checkModal', isMobile && 'checkModal-mob'].join(' ')}>
          <div className={['modalContent'].join(' ')}>
            <div className={'listHead'}>
            </div>
            <div className="list">
              {'Actualmente se accede a la versión de perú. Si necesita la versión de méxico, haga clic para cambiar'}
            </div>
            <div className="ok" onClick={this.handleGoCheck}>{'Confirmar'}</div>
            <div className="cancel" onClick={()=>{this.setState({checkModal: false})}}>{'cancelar'}</div>
          </div>
        </div>
        }
      </div>
    );
  }
}
export default PrivacyAgreement;
