import React, { Component } from "react";
import Home from "./Puerta";
import Politica from "./Page/suyoDatos/politica";
import Terminos from "./Page/suyoDatos/terminos";
import PoliticaMexc from "./Page/suyoDatos/politicaMexc";
import TerminosMexc from "./Page/suyoDatos/terminosMexc";
import PoliticaSuyo from "./Page/suyoDatos/politicaSuyo";
import TerminosSuyo from "./Page/suyoDatos/terminosSuyo";

import Datos from "./Page/suyoDatos/datos";
import ErrorInfo from "./Page/ErrorInfo";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import Mexc from "./Page/Mexc/datos";
import Suyo from "./Page/Suyo/datos";


const browserHistory = createBrowserHistory();
class App extends Component {
  render() {
    console.log("----App----");


    return (
      <Router history={browserHistory}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/errorInfo" component={ErrorInfo} />

          {/* pmPolitica pmTerminos  默认秘鲁版本 可弹框切换 */}
          {/* pmPoliticaMexc pmTerminos  秘鲁版本*/}
          {/* pmPoliticaMexc pmTerminosMexc  墨西哥版本 */}
          <Route exact path="/politica" component={Politica} />
          <Route exact path="/terminos" component={Terminos} />

          <Route exact path="/politicamexc" component={PoliticaMexc} />
          <Route exact path="/terminosmexc" component={TerminosMexc} />

          <Route exact path="/politicasuyo" component={PoliticaSuyo} />
          <Route exact path="/terminossuyo" component={TerminosSuyo} />



          {/* suyoDatos  默认秘鲁版本 可弹框切换 */}
          {/* mexcTosuyo  墨西哥版本 */}
          {/* suyoTomexc  秘鲁版本 */}
          <Route exact path="/suyodatos" component={Datos} />
          <Route exact path="/suyotomexc" component={Suyo} />
          <Route exact path="/mexctosuyo" component={Mexc} />
          
          <Route path="*" component={() => <Redirect to="/" />} />
        </Switch>
      </Router>
    );
  }
}


export default App;
