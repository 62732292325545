import React from "react";
import { enquireScreen } from "enquire-js";

import "./../CommonPage.less";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class ServiceAgreement extends React.PureComponent {
  state = {
    isMobile,
    // isScroll: false,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }

  render() {
    return (
      <div className={"contentWrapper"}>
        <h2>Términos y Condiciones de Uso</h2>
        <p>1. ACEPTACIÓN Y ALCANCE DE LOS TÉRMINOS Y CONDICIONES</p>
        <p></p>
        <p>
          Estos términos y condiciones de uso, en adelante “Términos”, aplican
          al uso de los servicios proporcionados por PrestMan a través de la
          aplicación móvil, en adelante “Aplicación”. Al instalar y utilizar la
          Aplicación, el usuario manifiesta su expresa aceptación de todos los
          Términos establecidos en este documento. La instalación y uso de la
          Aplicación se considerarán como una clara manifestación de la voluntad
          y aceptación de estos Términos. Si no está de acuerdo con los Términos
          de uso de la Aplicación y las políticas de privacidad de PrestMan, le
          instamos a no instalar ni utilizar la aplicación.
        </p>
        <p></p>
        <p>2. DEFINICIONES</p>
        <p></p>
        <p>
          (i) “Usuario”: Cualquier persona que utilice la Aplicación de
          PrestMan.
        </p>
        <p></p>
        <p>
          (ii) “Préstamo”: Servicio crediticio solicitado por el Usuario y
          otorgado por PrestMan a cambio de una compensación monetaria indicada
          y aceptada antes de la solicitud del usuario, según las condiciones
          del Contrato de Mutuo Dinerario en el Anexo 1.
        </p>
        <p></p>
        <p>
          (iii) “Teléfono móvil”: Dispositivo utilizado por el Usuario para
          instalar la Aplicación de PrestMan.
        </p>
        <p></p>
        <p>
          (iv) “Algoritmo crediticio”: Software y procedimientos propiedad de
          PrestMan para tomar decisiones en los servicios financieros brindados.
        </p>
        <p></p>
        <p>3. ¿QUÉ ES PrestMan?</p>
        <p></p>
        <p>
          PrestMan es una aplicación que permite al Usuario solicitar préstamos
          monetarios. Estos préstamos pueden ser otorgados o no por PrestMan
          según el algoritmo crediticio utilizado.
        </p>
        <p></p>
        <p>4. REGISTRO Y USO DE LA APLICACIÓN</p>
        <p></p>
        <p>
          a) Al registrarse, el Usuario acepta proporcionar información precisa,
          completa y actualizada necesaria para completar el proceso de
          registro. PrestMan puede realizar controles para verificar la
          veracidad de la información.
        </p>
        <p></p>
        <p>
          b) Al registrarse, el Usuario autoriza a la Aplicación a compartir
          información de uso y comportamiento crediticio con los contactos del
          usuario que también utilizan la aplicación.
        </p>
        <p></p>
        <p>
          c) Solo las personas con capacidad legal están autorizadas a utilizar
          la aplicación. Los menores de edad y aquellos sin capacidad legal
          deben abstenerse de usar la Aplicación.
        </p>
        <p></p>
        <p>
          d) PrestMan no se responsabiliza por daños resultantes de la pérdida o
          mal uso de la clave por terceros. El Usuario es el único responsable.
        </p>
        <p></p>
        <p>
          e) El registro de Usuario es personal y no se puede transferir a
          terceras personas.
        </p>
        <p></p>
        <p>
          f) El incumplimiento de los puntos señalados puede resultar en la
          suspensión temporal o definitiva de las cuentas de los Usuarios. El
          incumplimiento de las políticas internas puede llevar a
          determinaciones similares.
        </p>
        <p></p>
        <p>5. LIMITACIONES DE RESPONSABILIDAD</p>
        <p></p>
        <p>
          a) El Usuario reconoce que la Aplicación requiere permisos especiales
          en el Teléfono móvil y que PrestMan no será responsable de los efectos
          de la revocación de esos permisos. Los usuarios renuncian al derecho
          de presentar reclamaciones al respecto.
        </p>
        <p></p>
        <p>
          b) Dado que la Aplicación se mejora y actualiza continuamente,
          PrestMan no garantiza su disponibilidad sin interrupciones ni la
          ausencia de errores. Por lo tanto, no se responsabiliza por posibles
          perjuicios causados al Usuario por este motivo.
        </p>
        <p></p>
        <p>
          c) La solicitud de Préstamo está sujeta tanto al resultado del
          Algoritmo crediticio como a la disponibilidad de fondos de PrestMan.
          El Usuario reconoce que la solicitud no es vinculante al servicio de
          Préstamo bajo ningún supuesto.
        </p>
        <p></p>
        <p>6. OBLIGACIONES Y RESPONSABILIDADES DE LOS USUARIOS</p>
        <p></p>
        <p>
          a) El Usuario certifica que utiliza la Aplicación por voluntad propia
          y asume las responsabilidades y riesgos asociados.
        </p>
        <p></p>
        <p>
          b) El Usuario es responsable del consumo de datos o internet del
          dispositivo. Acepta que la Aplicación utilizará recursos de su plan de
          datos según su uso.
        </p>
        <p></p>
        <p>
          c) El Usuario que solicite un Préstamo se compromete a cumplir con las
          condiciones del Contrato de Mutuo Dinerario.
        </p>
        <p></p>
        <p>7. PROCEDIMIENTO DE ATENCIÓN Y RECLAMOS</p>
        <p></p>
        <p>
          Los Usuarios pueden presentar reclamos sobre los servicios de PrestMan
          por las vías establecidas en la normativa de protección al consumidor,
          como correo electrónico a servicio@prestman.tech.
        </p>
        <p></p>
        <p>8. POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS PERSONALES</p>
        <p></p>
        <p>
          De acuerdo con la legislación de protección de datos, el Usuario
          autoriza a PrestMan para el tratamiento de sus datos personales.
          PrestMan garantiza la seguridad y confidencialidad de la información,
          utilizándola para comunicaciones comerciales y mantenimiento de la
          relación contractual. El Usuario tiene derechos para solicitar
          información, acceso, actualización, rectificación, supresión,
          oposición y revocación del consentimiento.
        </p>
        <p></p>
        <p>9. SANCIONES</p>
        <p></p>
        <p>
          PrestMan puede notificar, suspender o cancelar la cuenta de cualquier
          Usuario y tomar acciones legales en caso de violación de los Términos.
          El Usuario no tendrá derecho a indemnización por la suspensión de su
          cuenta.
        </p>
        <p></p>
        <p>10. LICENCIA</p>
        <p></p>
        <p>
          a) PrestMan otorga a los Usuarios una licencia limitada, personal, no
          exclusiva, intransferible y revocable para usar la Aplicación según
          los Términos. PrestMan se reserva todos los derechos sobre la
          Aplicación.
        </p>
        <p></p>
        <p>
          b) PrestMan no se hace responsable por daños sufridos por el usuario
          que copia, transfiere, distribuye o utiliza contenido de la Aplicación
          de manera no autorizada.
        </p>
        <p></p>
        <p>11. CONDICIONES GENERALES</p>
        <p></p>
        <p>
          a) Los Términos no generan sociedad, franquicia o relación laboral
          entre los Usuarios y PrestMan.
        </p>
        <p></p>
        <p>
          b) PrestMan puede cambiar los Términos en cualquier momento sin previo
          aviso. Los cambios serán obligatorios automáticamente al publicarse en
          la página web: https://www.prestman.tech/terminos . Los
          Términos se rigen por la legislación peruana, y cualquier disputa se
          someterá a los tribunales de Lima.
        </p>
        <p></p>
        <p>
          d) Al registrarse y aceptar los Términos, el Usuario declara conocer y
          comprometerse a cumplir con estos Términos y demás políticas
          disponibles en el sistema.
        </p>
        <p></p>
        <p>
          e) Los cambios en los Términos se comunicarán mediante la Aplicación y
          el correo electrónico proporcionado por los Usuarios.
        </p>
        <p></p>
        <p>12. COSTOS POR SERVICIOS ASOCIADOS</p>
        <p></p>
        <p>
          El Usuario comprende y acepta que puede haber costos asociados al
          servicio de Préstamo, como costos de transferencia de fondos y costos
          de recaudación. PrestMan notificará oportunamente al usuario sobre
          estos costos.
        </p>
        <p></p>
        <p>13. DERECHOS DE PROPIEDAD INTELECTUAL</p>
        <p></p>
        <p>
          PrestMan es titular de la propiedad intelectual de la Aplicación,
          incluyendo código fuente, marcas, nombres comerciales, diseños,
          imágenes, vídeos, fotografías, lemas, slogans y cualquier creación
          protegida por la legislación aplicable. El uso de la Aplicación no
          transfiere derechos de propiedad intelectual a los Usuarios.
        </p>
        <p></p>
        <p>ANEXO 1: CONTRATO DE MUTUO DINERARIO</p>
        <p></p>
        <p>
          En caso de solicitud y aprobación de un Préstamo, se configura el
          Contrato de Mutuo Dinerario entre EL MUTUATARIO y PrestMan. Este
          contrato establece la entrega de una suma de dinero por parte de
          PrestMan al MUTUATARIO, con condiciones detalladas en las cláusulas
          siguientes:
        </p>
        <p></p>
        <p>PRIMERA: ANTECEDENTES</p>
        <p></p>
        <p>
          El MUTUATARIO solicita un préstamo de dinero a PrestMan, quien
          manifiesta su interés en otorgar dicho préstamo bajo las condiciones
          especificadas.
        </p>
        <p></p>
        <p>SEGUNDA: OBJETO</p>
        <p></p>
        <p>
          2.1. PrestMan entrega en calidad de MUTUO al MUTUATARIO la suma
          acordada en la Aplicación, con el fin de financiar las actividades del
          MUTUATARIO.
        </p>
        <p></p>
        <p>
          2.2. La suma del mutuo se depositará en la cuenta indicada por el
          MUTUATARIO en la Aplicación.
        </p>
        <p></p>
        <p>
          TERCERA: INTERÉS COMPENSATORIO, FORMA DE PAGO Y OBLIGACIONES DEL
          MUTUATARIO
        </p>
        <p></p>
        <p>
          3.1. El interés compensatorio será el aceptado por el MUTUATARIO en la
          Aplicación, ya sea en forma porcentual o como valor absoluto.
        </p>
        <p></p>
        <p>
          3.2. El contrato es válido hasta que se pague el préstamo, incluyendo
          capital, intereses, costos asociados, moras y penalidades.
        </p>
        <p></p>
        <p>
          3.3. El pago se realizará mediante abono en la cuenta indicada por la
          Aplicación.
        </p>
        <p></p>
        <p>
          3.4. La falta de pago da derecho a PrestMan a acciones como bloquear
          la pantalla del Teléfono móvil, reportar a centrales de riesgo y
          emprender acciones judiciales.
        </p>
        <p></p>
        <p>
          3.5. En caso de incumplimiento, la suma adeudada puede generar
          intereses moratorios, indicados en la Aplicación.
        </p>
        <p></p>
        <p>
          3.6. El MUTUATARIO puede devolver el monto antes de la fecha de
          vencimiento sin penalidad.
        </p>
        <p></p>
        <p>CUARTA: RESOLUCIÓN</p>
        <p></p>
        <p>
          PrestMan puede resolver el contrato en cualquier momento y exigir el
          pago inmediato de la suma prestada con intereses.
        </p>
        <p></p>
        <p>QUINTA: SUBSISTENCIA DE ESTIPULACIONES</p>
        <p></p>
        <p>
          La invalidez de una disposición no afectará la validez de las demás.
          Las partes buscarán cumplir los objetivos de la disposición nula con
          una nueva estipulación válida.
        </p>
        <p></p>
        <p>SEXTA: LEY APLICABLE</p>
        <p></p>
        <p>
          El contrato se rige por la ley peruana, y lo no regulado se rige por
          el Código Civil.
        </p>
        <p></p>
        <p>SÉPTIMA: SOLUCIÓN DE CONTROVERSIAS</p>
        <p></p>
        <p>
          Las partes acuerdan someter cualquier controversia a los tribunales de
          Lima.
        </p>
      </div>
    );
  }
}
export default ServiceAgreement;
