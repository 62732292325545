import React from "react";
import { enquireScreen } from "enquire-js";

import "./../CommonPage.less";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class PrivacyAgreement extends React.PureComponent {
  state = {
    isMobile,
    // isScroll: false,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }

  render() {
    return (
      <div className={"contentWrapper"}>
        <h1>AVISO DE PRIVACIDAD</h1>
        <p>
          La fecha de la ultima actualización: 07 de septiembre de 2021.En
          virtud de lo dispuesto por la Ley Federal de Protección de Datos
          Personales en Posesión de los Particulares (la “Ley”) y su legislación
          secundaria, Mobene Digital Tech Services, Sociedad Anónima de Capital
          Variable, (“PrestMan” y/o el “Responsable”), con dirección en calle
          Versalles 15 Oficinas 202, 203, 204 Colonia Juárez, Cuauhtémoc, 06600.
          Ciudad de México. México, le informa de manera expresa lo siguiente:
          Los datos personales que usted nos proporcione, ya sea de manera
          directa o indirecta, son confidenciales y estarán sujetos a la
          normatividad vigente aplicable, así como a estándares nacionales e
          internacionales en materia de protección de datos personales de los
          cuales usted es titular (el “Titular”), mismos que podrán consistir en
          los siguientes: (i) nombre completo, (ii) domicilio, (iii) correo
          electrónico, (iv) fecha y lugar de nacimiento, (v) nacionalidad, (vi)
          número de teléfono, (vii) Registro Federal de Contribuyentes (“RFC”),
          (viii) Clave Única de Registro de Población (“CURP”), (ix) estado
          civil, (x) datos de identificación y contacto, (xi) geolocalización,
          (xii) datos de redes sociales cuando así lo autorice el Titular,
          (xiii) datos patrimoniales, (xiv) ingresos y egresos a través de
          acceso a su cuenta del Servicio de Administración Tributaria y/o
          cuentas bancarias, (xv) motivo del crédito solicitado, (xvi) tipo de
          negocio, y (xvii) Datos biometría facial personal en elementos de
          reconocimiento facial a fin de corroborar su identidad (los “Datos
          Personales”). PrestMan no recabará Datos Personales de las referencias
          que en su caso otorguen los Titulares para la de contratación de algún
          servicio o producto . El consentimiento expreso del Titular para el
          tratamiento de los Datos Personales será recabado a través de nuestra
          página web www.prestman.tech y de la aplicación Informática que
          ponemos a su disposición (la “App”) o por cualquier otro medio
          electrónico, telefónico, de video u otra tecnología (los “Medios
          Electrónicos”), lo cual podrá suceder de manera automática y
          simultánea al tiempo que Usted haga contacto con los mismos; lo
          anterior para estar en posibilidad de brindarle los servicios y/o
          productos que ofrece PrestMan (los “Servicios”) y cumplir con las
          finalidades que se indican en este Aviso de Privacidad. PrestMan hace
          del conocimiento del Titular que todos los Datos Personales
          proporcionados se sujetarán al contenido de este Aviso de Privacidad.
        </p>
        <p></p>
        <p>Estimado usuario</p>
        <p></p>
        <p>
          Para evaluar su elegibilidad y facilitar el desembolso más rápido de
          su préstamo, necesitamos de los siguientes permisos para acceder a su:
          Localización, Calendario, SMS, Registros de Llamadas(Call Log),
          Cámara.
        </p>
        <p></p>
        <p>Localización</p>
        <p></p>
        <p>
          Verificar su ubicación, solo depositamos préstamos a usuarios
          Mexicanos.
        </p>
        <p></p>
        <p>Calendario</p>
        <p></p>
        <p>
          Se usa para recordarle la fecha de pago para evitar el impacto del
          pago vencido en su crédito
        </p>
        <p></p>
        <p>SMS (registro de SMS)</p>
        <p></p>
        <p>
          Recopilar y supervisar su SMS para ayudarle en reducir los riesgos
          asociados con su aplicación y brindar servicios personalizados.
        </p>
        <p></p>
        <p>Registros de Llamadas (Call Log)</p>
        <p></p>
        <p>
          Cuando se registra o inicia sesión, si elige obtener el código de
          verificación de voz, necesitamos obtener su información de grabación
          de llamadas (incluyendo: número de teléfono, tiempo de llamada, tipo
          de llamada). Esto puede confirmar aún más que hay una tarjeta SIM en
          el dispositivo para recibir el código de verificación de voz y evitar
          cualquier fraude potencial. Tenga la seguridad de que todos los datos
          se han cifrado y cargado de forma segura a
          (https://go.prestman.tech/api), nunca divulgaremos su información a
          ningún individuo o tercero.
        </p>
        <p></p>
        <p>Cámara</p>
        <p></p>
        <p>
          Cuando solicita un préstamo, necesitamos verificar si lo solicita el
          mismo cliente titular para evitar que le roben su información.
        </p>
        <p></p>
        <p>Imagen</p>
        <p></p>
        <p>
          Necesitamos su permiso para acceder a la información de
          almacenamiento, lo cual permitirá que la aplicación le solicite cargar
          fotos y/o documentos durante el proceso de solicitud de préstamo para
          completar la solicitud. PrestMan cargará y transmitirá los datos de
          almacenamiento desde su teléfono a https://go.prestman.tech/api
        </p>
        <p></p>
        <p>Contactos</p>
        <p></p>
        <p>
          Con su permiso, recopilaremos sus 2 contactos de emergencia (incluida
          su relación con el contacto de emergencia, número de teléfono móvil y
          nombre) completándolos manualmente. Los datos se cargarán en nuestros
          servidores a través de una conexión segura.
          (https://go.prestman.tech/api)
        </p>
        <p>
          Los números de teléfono y los nombres cargados y transmitidos se
          utilizarán para la gestión de riesgos y controles antifraude.
        </p>
        <p>
          No accederemos ni obtendremos información de su libreta de
          direcciones, y PrestMan nunca compartirá sus datos con terceros sin su
          permiso.
        </p>
        <p></p>
        <p>Datos de las aplicaciones instaladas</p>
        <p></p>
        <p>
          PrestMan recopila, almacena y monitorea e sube a
          https://go.prestman.tech/api la información de los metadatos de cada
          aplicación instalada, incluyendo el nombre de la aplicación y del
          paquete, la hora de instalación y actualización, el nombre de la
          versión y el código de la versión de todas las aplicaciones instaladas
          en su dispositivo. Utilizamos esta información para evaluar su
          solvencia y evitar el fraude en los préstamos. PrestMan nunca
          compartirá los datos con terceros sin su permiso. Sólo se recopilarán
          bajo su consentimiento. Los datos serán transferidos y cargados
          mediante una conexión segura (https).
        </p>
        <p></p>
        <p>———— FINALIDAD ————</p>
        <p></p>
        <p>
          PrestMan manifiesta en este acto que recaba los Datos Personales para
          ser utilizados de la manera siguiente:
        </p>
        <p></p>
        <p>I. Necesarias</p>
        <p></p>
        <p>
          a. Cumplir con la relación contractual derivada de la solicitud de
          servicio y / o contrato.
        </p>
        <p></p>
        <p>
          b. Verificar, confirmar y validar la identidad del Titular, así́ como
          la veracidad de la información que proporciona como propia,
          referencias, obligados solidarios, avales, según resulte aplicable de
          acuerdo con el servicio contratado con PrestMan.
        </p>
        <p></p>
        <p>
          c. Gestionar, operar y dar seguimiento los servicios que solicite o
          contrate con PrestMan.
        </p>
        <p></p>
        <p>
          d. Analizar la información proporcionada por el titular para
          determinar el nivel de riesgo, capacidad de pago, estado de la
          transacción y cualquier otra valoración que exijan las leyes y
          normativas para la prestación de servicios de acuerdo con la normativa
          legal vigente.
        </p>
        <p></p>
        <p>
          e. Confirmar formalmente la relación contractual entre PrestMan y el
          titular.
        </p>
        <p></p>
        <p>
          f. Incluirlo en nuestra base de datos, integrar expedientes, de manera
          directa o a través de un tercero.
        </p>
        <p></p>
        <p>
          g. Procesar las solicitudes que realice a través de nuestros Medios
          Electrónicos y/o llamadas.
        </p>
        <p></p>
        <p>
          h. Crear su perfil como usuario en la App. Verificación e
          investigación de capacidad crediticia.
        </p>
        <p></p>
        <p>
          i. Consultar y realizar investigaciones crediticias ante sociedades de
          información crediticia que estime conveniente.
        </p>
        <p></p>
        <p>j. Ofrecer una ampliación o mejora de los Servicios contratados.</p>
        <p></p>
        <p>k. Informar sobre actualizaciones de PrestMan.</p>
        <p></p>
        <p>
          l. Llevar a cabo la inteligencia comercial, realizar investigaciones
          de mercado, darle visibilidad a su actividad en nuestros Medios
          Electrónicos, seguimiento de calidad, evaluación y mejora de nuestros
          Medios Electrónicos.
        </p>
        <p></p>
        <p>
          m. Enviar comunicaciones a través de nuestros Medios Electrónicos
          relacionado con los Servicios contratados.
        </p>
        <p></p>
        <p>n. Realizar gestiones de cobranza.</p>
        <p></p>
        <p>
          o. Aplicación de encuestas de calidad y satisfacción de servicios.
        </p>
        <p></p>
        <p>
          p. Proveer los servicios y productos que ha solicitado de nuestra
          empresa.
        </p>
        <p></p>
        <p>
          q. Informarte sobre los cambios en los mismos o nuevos productos o
          servicios que estén relacionados con el contratado o adquirido por el
          cliente.
        </p>
        <p></p>
        <p>II. No necesarias:</p>
        <p></p>
        <p>
          a. Envío de ofertas o descuentos de productos y artículos,
          promociones, mercadotecnia o eventos especiales que puedan ser de
          interés del Titular.
        </p>
        <p></p>
        <p>
          b. Enviar nuestro boletín de noticias y ofertas, así́ como beneficios y
          servicios ofrecidos por socios comerciales. El Titular de los Datos
          Personales podrá negarse al tratamiento de sus Datos Personales para
          las finalidades no necesarias, enviando un correo a la siguiente
          dirección electrónica: servicio@prestman.tech; en el entendido de que,
          en caso de no recibir la solicitud correspondiente, se entenderá por
          consentido el uso de su información para todas las finalidades antes
          mencionadas.
        </p>
        <p></p>
        <p>Datos Personales Que Utilizamos</p>
        <p></p>
        <p>
          Las categorías de datos personales que le podrán ser requeridas para
          ser recabados y que están sujetas a tratamiento son de manera
          enunciativa mas no limitativa:
        </p>
        <p></p>
        <p>1. Datos de identificación, Contacto y Laborales.</p>
        <p></p>
        <p>2. Datos migratorios y legales.</p>
        <p></p>
        <p>3. Datos patrimoniales y/o financieros.</p>
        <p></p>
        <p>
          Para llevar a cabo las finalidades descritas en el presente Aviso de
          Privacidad, utilizaremos los siguientes datos personales de los cuales
          usted es Titular:
        </p>
        <p></p>
        <p>1. Registro Federal de Contribuyentes.</p>
        <p></p>
        <p>2. Dirección o direcciones.</p>
        <p></p>
        <p>3. Datos patrimoniales y/o financieros.</p>
        <p></p>
        <p>
          Para llevar a cabo las finalidades descritas en el presente Aviso de
          Privacidad, utilizaremos los siguientes datos personales de los cuales
          usted es Titular:
        </p>
        <p></p>
        <p>1. Registro Federal de Contribuyentes.</p>
        <p></p>
        <p>2. Dirección o direcciones.</p>
        <p></p>
        <p>3. Nombre completo.</p>
        <p></p>
        <p>4. Domicilio(s) incluyendo código postal.</p>
        <p></p>
        <p>5. Fecha de nacimiento.</p>
        <p></p>
        <p>6. Teléfono celular.</p>
        <p></p>
        <p>7. Teléfono fijo.</p>
        <p></p>
        <p>8. Clave Única de Registro de Población.</p>
        <p></p>
        <p>9. Estado civil.</p>
        <p></p>
        <p>10. Dependientes económicos.</p>
        <p></p>
        <p>11. Copia de identificación oficial.</p>
        <p></p>
        <p>12. Referencias personales.</p>
        <p></p>
        <p>13. Datos de cuenta bancaria.</p>
        <p></p>
        <p>
          Los datos personales podrán ser recabados por PrestMan requeridos a
          través de la aplicación informática de PrestMan, vía telefónica.
        </p>
        <p></p>
        <p>
          Los datos personales antes mencionados serán solicitados por PrestMan
          cuando usted:
        </p>
        <p></p>
        <p>
          l Realice su registro en la aplicación informática para la solicitud
          de un crédito.
        </p>
        <p></p>
        <p>l Se comunique vía telefónica con PrestMan.</p>
        <p></p>
        <p>——— Transferencia de Datos Personales ———</p>
        <p></p>
        <p>
          PrestMan compartirá sus Datos Personales con: (i) sus sociedades
          controladoras, subsidiarias o afiliadas bajo el control común de
          PrestMan, así como a nuestra sociedad matriz o a cualquier sociedad de
          nuestro grupo corporativo que opere bajo los mismos procesos y
          políticas internas; (ii) con autoridades competentes, en caso de ser
          solicitados, en términos de la legislación aplicable; (iii) sociedades
          o compañías encargadas de cobranza y recolección de pagos; (iv) con
          otras empresas en caso de ser liquidados, escindidos, fusionados o
          cualquier otra operación similar por esta compañía; (v) terceros
          incluidos entidades financieras, para cesión o descuento de cartera de
          crédito, endoso o cualquier otra forma de negociación de la cartera
          crediticia; (vi) socios comerciales con quien se tengan celebrados
          contratos para la comercialización de productos y/o servicios en
          beneficio del Titular, y (vii) en cualquier otro de los supuestos
          establecidos en el artículo 10 y 37 de la Ley.
        </p>
        <p></p>
        <p>
          ———— Medio y Procedimiento para ejercer el derecho de acceso,
          rectificación, cancelación y oposición establecido en la Ley
          (“Derechos ARCO”) y Revocación del Consentimiento ————
        </p>
        <p></p>
        <p>
          En virtud de que el Titular tiene derecho a la protección de los Datos
          Personales, a los derechos ARCO de los mismos, así como a manifestar
          su oposición o revocación, en los términos que establece la Ley, podrá
          ejercer los Derechos ARCO o la revocación del consentimiento en forma
          personal, o bien, a través de su representante legal, mediante la
          generación y envío de la solicitud correspondiente a la siguiente
          dirección de correo electrónico mobenedigital@gmail.com del
          Responsable, debiendo acompañar a su solicitud una copia de la
          identificación oficial vigente del Titular o, en su caso, de su
          representante legal y el poder donde consten las facultades para
          representarlo, así como los siguientes datos: i) nombre completo; ii)
          domicilio actual; iii) teléfono(s) de contacto; iv) correo
          electrónico; v) ID del Cliente, así como el ID del producto
          contratado; vi) la solicitud expresa respecto del ejercicio de
          cualquiera de los derechos de acceso, rectificación, cancelación u
          oposición al tratamiento de sus datos; o bien, vii) la revocación del
          consentimiento al tratamiento; y, una breve descripción de las razones
          por las que remite la solicitud al Responsable.
        </p>
        <p></p>
        <p>
          Después de presentar la solicitud de acuerdo con las cláusulas
          anteriores, PrestMan publicará la decisión aprobada dentro de los 20
          (veinte) días posteriores a la recepción de la solicitud. Si la
          solicitud es procedente, entrará en vigor dentro de los quince
          (quince) días hábiles contados a partir de la fecha en que el
          responsable comunique la respuesta. Si la información proporcionada en
          su solicitud es incorrecta o insuficiente, o no se adjuntan los
          documentos necesarios para acreditar su identidad o el representante
          legal correspondiente, el Responsable solicitará la documentación
          faltante en un término de 5 (cinco) días hábiles posteriores a la
          recepción de la misma, a fin de contar con los elementos suficientes
          para valorar si es procedente o no su solicitud. Asimismo, el Titular
          podrá solicitar la corrección de sus Datos Personales, en cuyo caso el
          Responsable contará con el plazo de 10 (diez) días hábiles a partir de
          la fecha de recepción de la solicitud de corrección de Datos
          Personales para evaluar y corregir las omisiones o faltantes. Si no
          responde dentro del plazo antes mencionado, la solicitud
          correspondiente se considerará no enviada. El Titular será responsable
          de mantener actualizados sus Datos Personales ante el Responsable. Por
          tanto, en todo caso, el Titular garantiza y responde de la exactitud,
          veracidad, vigencia y autenticidad de los Datos Personales
          facilitados, y se compromete a actualizar su contenido oportunamente e
          informar al Responsable de cualquier cambio. Si la solicitud del
          ejercicio de los Derechos ARCO está relacionada con el ejercicio del
          derecho de acceso, PrestMan le facilitará información o datos
          personales mediante copia simple y / o documento electrónico en la
          dirección electrónica que designe el Titular. El Titular de los Datos
          Personales solo puede retirar el consentimiento para el procesamiento
          de los mismos de acuerdo con los procedimientos anteriores, y conforme
          a lo establecido por la Ley, sujetándose el Titular a los
          procedimientos legales que apliquen. PrestMan puede negarse a ejercer
          los Derechos ARCO cuando lo permitan las leyes y reglamentos
          aplicables en la materia.
        </p>
        <p></p>
        <p>
          En el caso de que la modificación o anulación anterior no impacte en
          el cumplimiento de las obligaciones derivadas de la relación jurídica
          vigente entre el Titular y el Responsable, el Titular podrá retirar su
          consentimiento al tratamiento de sus Datos Personales sin efecto
          retroactivo. El procedimiento para revocar el consentimiento por parte
          del Titular para el uso de sus Datos Personales, será el mismo que el
          establecido para el ejercicio de los Derechos ARCO en el apartado
          anterior.
        </p>
        <p></p>
        <p>———— Eliminación de cuentas y datos ————</p>
        <p></p>
        <p>
          Le brindamos el derecho de cancelar su cuenta después de liquidar las
          órdenes de préstamo. Si desea dejar de usar nuestro servicio y
          eliminar su cuenta, ingrese a www.prestman.tech/suyoDatos para enviar una
          solicitud de eliminación, le pediremos que proporcionar información de
          su cuenta y pruebas relacionadas para verificar y validar su
          identidad; Después de la verificación, eliminaremos su cuenta y los
          datos relacionados.
        </p>
        <p></p>
        <p>
          Métodos para restringir el uso o la divulgación de sus Datos
          Personales
        </p>
        <p></p>
        <p>
          El Titular de los Datos Personales puede restringir su uso o
          divulgación de acuerdo con los procedimientos previstos en la sección
          "Ejercicio de los Derechos ARCO y revocación del consentimiento".La
          autorización debe incluir al menos 1) El procesamiento del Responsable
          de acuerdo con el método acordado en este acuerdo, 2) Compartir dicha
          información personal con el Titular, y 3）El Titular utiliza dichos
          datos e información personal para llevar a cabo los servicios
          acordados en este acuerdo y otros servicios de validación crediticia
          de big data del Titular.
        </p>
        <p></p>
        <p>—Cookies y Protocolo de Seguridad en Línea—</p>
        <p></p>
        <p>
          El Responsable obtiene información a través de la página de internet
          mediante el uso de cookies, entendiendo como “cookie” el archivo de
          datos que se almacena en el disco duro de la computadora del Titular,
          cuando éste tiene acceso al Sitio Web del Responsable. Dichos archivos
          pueden contener información tal como la identificación proporcionada
          por el Titular, o información para rastrear las páginas de preferencia
          del mismo, para monitorear el comportamiento como usuario de internet,
          brindarles un mejor servicio y experiencia de usuario al navegar en el
          Sitio Web del Responsable. Una cookie no puede leer los datos o
          información del disco duro del Titular ni leer las cookies creadas por
          otros sitios o páginas. En caso de que el Titular desee rechazar las
          cookies o aceptarlas de manera selectiva, puede llevarlo a cabo a
          través del ajuste de las preferencias del navegador.Los Medios
          Electrónicos pueden incluir enlaces a sitios web de terceros ajenos al
          Responsable, respecto de los cuales este último no asume ninguna
          titularidad, obligación o responsabilidad alguna.
        </p>
        <p></p>
        <p>———— Consentimiento ————</p>
        <p></p>
        <p>
          Se entenderá que al utilizar nuestros medios electrónicos y / o con
          los servicios proporcionados por nosotros para entrar en el propósito
          descrito en este artículo, usted reconoce y acepta expresamente este
          Aviso de Privacidad, y se compromete a llevarlo a cabo en todos sus
          términos y condiciones. Si tiene alguna pregunta sobre nuestras
          notificaciones, envíe un correo electrónico a servicio@prestman.tech.
        </p>
        <p></p>
        <p>—— Disponibilidad de aviso de privacidad ——</p>
        <p></p>
        <p>
          El propietario puede consultar la declaración de privacidad en nuestro
          sitio web www.prestman.tech y en la aplicación en cualquier momento,
          incluso si la declaración de privacidad ha sido aceptada.
        </p>
        <p></p>
        <p>—— Modificaciones al Aviso de Privacidad——</p>
        <p></p>
        <p>
          El Responsable se reserva el derecho de actualizar periódicamente el
          Aviso de Privacidad conforme a los cambios de las prácticas de
          información, en atención a las reformas legislativas, políticas
          internas o nuevos requerimientos para la prestación de los servicios o
          productos.
        </p>
        <p></p>
        <p>
          Las modificaciones estarán disponibles al público a través de nuestra
          página de Internet, por lo que el Titular podrá consultarlas en el
          sitio web www.prestman.tech, en la sección Aviso de Privacidad.
        </p>
        <p></p>
      </div>
    );
  }
}
export default PrivacyAgreement;
